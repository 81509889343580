import 'bootstrap';
import Popper from 'popper.js/dist/umd/popper';
import $ from 'jquery';

try {
  window.$ = $;
  window.jQuery = $;

  window.Popper = Popper;
} catch (e) { /* empty */ }
