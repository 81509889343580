import $ from 'jquery';
import IMask from 'imask';

let mobile = false;
const $body = $('body');
if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|Windows Phone)/)) {
  mobile = true;
}

$(document).ready(() => {
  $('.wrap-steps a:not(.open-all)').on('click', () => {
    window.alert('При використанні матеріалів - просимо вказувати назву проекту та сайт !');
  });

  // eslint-disable-next-line no-multi-assign
  document.oncut = document.oncopy = () => {
    window.alert('При використанні матеріалів - просимо вказувати назву проекту та сайт !');
  };

  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  if (window.location.hash && isChrome) {
    setTimeout(() => {
      const { hash } = window.location;
      window.location.hash = '';
      window.location.hash = hash;

      $(`${hash} button`).trigger('click');
    }, 1000);
  }

  $('.open-all').on('click', () => {
    $(this).parent().find('.accordion .card .collapse').each(() => {
      $(this).collapse('show');
    });
  });

  if (mobile === true) {
    $('body').addClass('mobile');
  } else {
    $('body').addClass('desktop');
  }

  /* Menu open */
  $('.menu-open').on('click', () => {
    const $menuElem = $('#wrapper, .menu-open, .slide-menu');
    $menuElem.toggleClass('open');

    if ($body.hasClass('hideOverflow')) {
      $body.removeClass('hideOverflow');
    } else {
      $body.addClass('hideOverflow');
    }
  });

  /* Scroll */
  $(document).scroll(() => {
    const y = $(this).scrollTop();
    if (y > 500) {
      $('.button-up').fadeIn();
    } else {
      $('.button-up').fadeOut();
    }
  });

  /* scroll-to */
  $('.scroll-to').on('click', (e) => {
    e.preventDefault();
    const $linkTo = $(this).data('scroll-to');

    $('html, body').animate({
      scrollTop: $($linkTo).offset().top,
    }, 800);
  });

  /* Set phone mask to main phone field */
  const phoneInputElement = document.querySelector('#tel');
  if (phoneInputElement) {
    IMask(phoneInputElement, {
      mask: '(000) 000-0000',
      placeholderChar: '#',
    });
  }

  /* Set phone mask for old phones */
  const phoneContainerInputElement = document.querySelector('#phones-container input');
  if (phoneContainerInputElement) {
    IMask(phoneContainerInputElement, {
      mask: '(000) 000-0000',
      placeholderChar: '#',
    });
  }

  /* Add more phones */
  $('.add-phone').on('click', (e) => {
    e.preventDefault();

    const container = $('#phones-container');
    const phonesCount = $('#phones-container > div').length;

    const phoneID = phonesCount + 1;

    container.append(`
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="tel-${phoneID}">Додатковий телефон</label>
          <input
            id="tel-${phoneID}"
            type="text"
            name="phones[]"
            placeholder="(xxx) xxx-xxxx"
          />
        </div>
      </div>`);

    const newPhoneContainerInputElement = document.querySelector(`#phones-container #tel-${phoneID}`);

    if (newPhoneContainerInputElement) {
      IMask(newPhoneContainerInputElement, {
        mask: '(000) 000-0000',
        placeholderChar: '#',
      });
    }
  });

  const news = $('.news-carousel');

  if (news) {
    news.owlCarousel({
      loop: true,
      margin: 20,
      nav: true,
      dots: true,
      dotsEach: 1,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        767: {
          items: 3,
          nav: false,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 4,
          dots: false,
        },
      },
    });
  }

  const customInputFileElement = $('#customFile');

  if (customInputFileElement) {
    customInputFileElement
      .on('change', function (e) {
        const fileName = e.target.files[0].name;
        $(this)
          .next('.custom-file-label')
          .html(fileName);
      });
  }
});
