/* global google */
// eslint-disable-next-line import/no-extraneous-dependencies
import { MarkerClusterer } from '@googlemaps/markerclusterer';

const googleMapWithMarkers = async (locations) => {
  const { Map, InfoWindow } = await google.maps.importLibrary('maps');
  const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

  const center = {
    lat: 49.23800899780855,
    lng: 30.920982500000036,
  };

  const map = new Map(document.getElementById('map'), {
    zoom: 6,
    center,
    gestureHandling: 'greedy',
    mapId: 'YOUTH_COUNCIL',
  });

  const markers = locations.map((location) => {
    const preparedLocation = {
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng),
    };

    const infoWindow = new InfoWindow({
      content: `<div style="fontsize: 18px;">${location.text}</div>`,
    });

    const marker = new AdvancedMarkerElement({
      position: preparedLocation,
      // title: location.title
    });

    marker.addListener('click', () => {
      infoWindow.open(map, marker);
    });

    return marker;
  });

  // eslint-disable-next-line no-new
  new MarkerClusterer({ map, markers });

  return map;
};

const googleMapWithMarkersAndSearch = async (locations) => {
  const map = await googleMapWithMarkers(locations);

  const searchInputField = document.querySelector('input[name=text]');
  const searchResultList = document.querySelector('#searchResultList');

  if (searchInputField && searchResultList) {
    document.addEventListener('click', (event) => {
      const { target } = event;

      if (!target.closest('.form-group.has-search')) {
        searchResultList.classList.remove('show');
        searchResultList.innerHTML = ''; // Clear
      }
    });

    searchInputField.addEventListener('input', (event) => {
      const searchInputText = event.target.value;

      if (searchInputText.trim().length === 0) {
        searchResultList.classList.remove('show');
        searchResultList.innerHTML = ''; // Clear
        return;
      }

      const searchListFiltered = locations.filter((obj) => obj.title.toLowerCase()
        .includes(searchInputText.toLowerCase()))
        .slice(0, 10);

      if (searchListFiltered.length === 0) {
        return;
      }

      searchResultList.classList.add('show');
      searchResultList.innerHTML = ''; // Clear

      for (let i = 0; i < searchListFiltered.length; i++) {
        const point = searchListFiltered[i];
        const pointElement = document.createElement('button');
        pointElement.classList.add('dropdown-item');
        pointElement.type = 'button';
        pointElement.onclick = () => {
          searchInputField.value = point.title;

          map.setZoom(13);
          map.setCenter(new google.maps.LatLng(parseFloat(point.lat), parseFloat(point.lng)));
          searchResultList.innerHTML = '';
          searchResultList.classList.remove('show');
        };

        const { title } = point;
        const index = title.toLowerCase()
          .indexOf(searchInputText.toLowerCase());

        if (index >= 0) {
          pointElement.innerHTML = `${title.substring(0, index)}<span class='highlight'>${title.substring(index, index + searchInputText.length)}</span>${title.substring(index + searchInputText.length)}`;
        }

        searchResultList.appendChild(pointElement);
      }
    });
  }
};

window.googleMapWithMarkers = googleMapWithMarkers;
window.googleMapWithMarkersAndSearch = googleMapWithMarkersAndSearch;
